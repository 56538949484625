import { lazy } from 'react';
import { PageTypes } from './enum';

const PersonalAreaLayout = lazy(() => import('../pages/personalAreaController/personalAreaLayout'));
const Fcoin = lazy(() => import('../pages/personalAreaController/Fcoin'));
const MemberArea = lazy(() => import('../pages/personalAreaController/MemberArea'));
const PersonalInfoPage = lazy(() => import('../pages/personalAreaController/PersonalInfoPage'));
const MultiplePayDepositPage = lazy(() => import('../pages/payController/MultiplePayDepositPage'));
const BillResend = lazy(() => import('../pages/billResentController/billResend'));
const OrderHistoryLayout = lazy(() => import('../pages/orderHistoryController/orderHistoryLayout'));
const GetBillSearchInitPage = lazy(() => import('../pages/billSearchController/getBillSearchInitPage'));
const GetBillSearchOTPLoginPage = lazy(() => import('../pages/billSearchController/getBillSearchOTPLoginPage'));
const BillPaymentDetails = lazy(() => import('../pages/billSearchController/BillPaymentDetails'));
const CurrentPaymentPage = lazy(() => import('../pages/billAndPaymentController/CurrentPaymentPage'));
const SixMonthBillPage = lazy(() => import('../pages/billSearchController/SixMonthBillPage'));
const MergeBill = lazy(() => import('../pages/billMergeController/MergeBill'));
const BillNotifySettingPage = lazy(() => import('../pages/billAndPaymentController/BillNotifySettingPage'));
const GetUnpaidAmoutAndUsedbytesPage = lazy(() =>
  import('../pages/unpaidAmoutAndUsedbytesController/getUnpaidAmoutAndUsedbytesPage')
);
const UsedBytesSearchPage = lazy(() => import('../pages/usedbytesSearchController/usedBytesQueryPage'));
const FourGExtraBuyPage = lazy(() => import('../pages/four4GExtraBuyController/4GExtraBuyPage'));
const FourGPurchaseRecord = lazy(() => import('../pages/four4GExtraBuyQueryController/FourGPurchaseRecord'));
const GetPhoneBillPaySettingInitPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingInitPage')
);
const GetPhoneBillPaySettingCheckResetPasswordPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage')
);
const GetPhoneBillPaySettingSelectAmountPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage')
);
const GetPhoneBillPaySettingOpenPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage')
);
const GetPhoneBillPaySmsSetting = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySmsSetting')
);
// const GetPhoneBillPaySettingLimitsRaised = lazy(() => import ('../pages/phoneBillPaySettingController/getPhoneBillPaySettingLimitsRaised'));
const GetPhoneBillPayFixAmountSetting = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPayFixAmountSetting')
);
const AddressChangePage = lazy(() => import('../pages/billSettingConrtoller/addressChangePage'));
const GetPhoneBillPaySettingSmsValidateErrorPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage')
);
const VoiceMailPage = lazy(() => import('../pages/voiceMailController/VoiceMailPage'));
const PhoneRingBell = lazy(() => import('../pages/phoneRingBellController/phoneRingBell'));
const PayExplanation = lazy(() => import('../pages/payExplanationController/PayExplanation'));
const AutoPayPage = lazy(() => import('../pages/AutoPayController/AutoPayPage'));
const AutoPayApplyPage = lazy(() => import('../pages/AutoPayController/AutoPayApplyPage'));
const CreditcardDateextension = lazy(() => import('../pages/AutoPayController/CreditcardDateextension'));
const CancelAutopayment = lazy(() => import('../pages/AutoPayController/CancelAutopayment'));
const QRateInitPage = lazy(() => import('../pages/qRateController/qRateInitPage'));
const RateChangeInitPage = lazy(() => import('../pages/rateChangeController/RateChangeInitPage'));
const BestRateQueryPage = lazy(() => import('../pages/bestRateController/bestRateQueryPage'));
const GetQueryContractsInfoPage = lazy(() => import('../pages/queryContractsInfoController/getQueryContractsInfoPage'));
const FreeCourseInitPage = lazy(() => import('../pages/freeCourseController/FreeCourseInitPage'));
const PhoneFilterInitPage = lazy(() => import('../pages/phoneFilterController/phoneFilterInitPage'));
const PhoneFilterResultPage = lazy(() => import('../pages/phoneFilterController/phoneFilterResultPage'));
const PhoneFilterInstruction = lazy(() => import('../pages/phoneFilterController/phoneFilterInstruction'));
const PhoneFilterBlackListPage = lazy(() => import('../pages/phoneFilterController/phoneFilterBlackListPage'));
const VolteSetting = lazy(() => import('../pages/volteController/volteSetting'));
const ResumeCall = lazy(() => import('../pages/roamingConfigController/ResumeCall'));
const ParkingfeeChangeRecordPage = lazy(() => import('../pages/parkingfeeController/parkingfeeChangeRecordPage'));
const ChangeRecord = lazy(() => import('../pages/queryRecordController/changeRecordPage'));
const GetQueryRecordPage = lazy(() => import('../pages/queryRecordController/getQueryRecordPage'));
const PrepaidRoamingBuyPage = lazy(() => import('../pages/prepaidRoamingBalanceController/prepaidRoamingBuyPage'));
const PrepaidRoamingQueryPage = lazy(() => import('../pages/prepaidRoamingBalanceController/prepaidRoamingQueryPage'));
const PrepaidRapidDepositPage = lazy(() => import('../pages/prepaidDepositController/prepaidRapidDepositPage'));
const PrepaidExtraBuyPage = lazy(() => import('../pages/prepaidExtraBuyController/prepaidExtraBuyPage'));
const EBillChangeNoticePage = lazy(() => import('../pages/eBillSettingController/eBillChangeNoticePage'));
const EBillChangeHistoryQueryPage = lazy(() => import('../pages/eBillSettingController/eBillChangeHistoryQueryPage'));
const PrepaidDetailQueryPage = lazy(() => import('../pages/prepaidDepositController/prepaidDetailQueryPage'));
const PrepaidDepositPage = lazy(() => import('../pages/prepaidDepositController/prepaidDepositPage'));
const DepositCardPage = lazy(() => import('../pages/prepaidDepositController/depositCardPage'));
const GetBarcodePayInitPage = lazy(() => import('../pages/billBarcodePayController/getBarcodePayInitPage'));
const ServiceCoveragePage = lazy(() => import('../pages/serviceCoverageController/serviceCoveragePage'));
const CancelEletricBill = lazy(() => import('../pages/eletricBillController/CancelEletricBill'));
const Store = lazy(() => import('../pages/help-center/Store'));
const WarrantyInitPage = lazy(() => import('../pages/warrantyController/warrantyInitPage'));
const WarrantyInfoPage = lazy(() => import('../pages/warrantyController/warrantyInfoPage'));
const NumberSearchPage = lazy(() => import('../pages/help-center/queryFETMsisdnPage'));
const Reminder = lazy(() => import('../pages/help-center/Reminder'));
const BookingMsisdn = lazy(() => import('../pages/help-center/BookingMsisdn'));
const PayPage = lazy(() => import('../pages/payController/payPage'));
const PayDepositPage = lazy(() => import('../pages/payController/payDepositPage'));
const RoamingPlanHomePage = lazy(() => import('../pages/roamingPlanController/roamingPlanHomePage'));
const ApplyRoamingOfferPage = lazy(() => import('../pages/roamingPlanController/applyRoamingOfferPage'));
const RoamingApplicationRecordPage = lazy(() => import('../pages/roamingPlanController/roamingApplicationRecordPage'));
const AttachRoamingOfferPage = lazy(() => import('../pages/roamingPlanController/attachRoamingOfferPage'));
const CancelRoamingOfferPage = lazy(() => import('../pages/roamingPlanController/cancelRoamingOfferPage'));
const ModifyRoamingOfferPage = lazy(() => import('../pages/roamingPlanController/modifyRoamingOfferPage'));
const InternalAgentApplyRoamingOfferPage = lazy(() =>
  import('../pages/roamingPlanInternalAgentController/internalAgentApplyRoamingOfferPage')
);
const InternalAgentAttachRoamingOfferPage = lazy(() =>
  import('../pages/roamingPlanInternalAgentController/internalAgentAttachRoamingOfferPage')
);
const ParkingfeeInfoPage = lazy(() => import('../pages/parkingfeeController/parkingfeeInfoPage'));
const ParkingfeeInitPage = lazy(() => import('../pages/parkingfeeController/parkingfeeInitPage'));
const ParkingfeeReceiveMoneyRecordPage = lazy(() =>
  import('../pages/parkingfeeController/parkingfeeReceiveMoneyRecordPage')
);
const ResultPage = lazy(() => import('../pages/resultController/ResultPage'));
const ResultWithCampaignPage = lazy(() => import('../pages/resultController/resultWithCampaignPage'));
const ProcedurePage = lazy(() => import('../pages/resultController/ProcedurePage'));
const International = lazy(() => import('../pages/internationalController/International'));
const PrepaidRoamingCreditCardPage = lazy(() =>
  import('../pages/prepaidRoamingBalanceController/prepaidRoamingCreditCardPage')
);
const GetPhoneBillPaySettingResultPage = lazy(() =>
  import('../pages/phoneBillPaySettingController/getPhoneBillPaySettingResultPage')
);
const PrepaidExtrsBuyCreditCardPage = lazy(() =>
  import('../pages/prepaidExtraBuyController/prepaidExtrsBuyCreditCardPage')
);
const PrepaidDepositCreditCardSuccess = lazy(() =>
  import('../pages/prepaidDepositController/prepaidDepositCreditCardSuccess')
);
const CreditcardPayResultPage = lazy(() => import('../pages/payController/creditCardPayResultPage'));
const MultipleCreditCardPayResultPage = lazy(() => import('../pages/payController/MultipleCreditCardPayResultPage'));
const PUKSearch = lazy(() => import('../pages/roamingConfigController/PUKSearch'));
const HappyGoLayout = lazy(() => import('../pages/happyGoController/HappyGoLayout'));
const RepairProgressInit = lazy(() => import('../pages/warrantyController/repairProgressInitPage'));
const RepairProgressInfo = lazy(() => import('../pages/warrantyController/repairProgressInfoPage'));
const ElectronicInvoceSetting = lazy(() => import('../pages/electronicInvoiceController/ElectronicInvoiceSetting'));
const EpaperSubscribePage = lazy(() => import('../pages/memberDataController/epaperSubscribePage'));
const LossReport = lazy(() => import('../pages/roamingService/LossReport'));
const StoreReserve = lazy(() => import('../pages/storeReserveController/storeReserve'));
const LocalCardEsimHistoryPage = lazy(() => import('../pages/localCardEsimController/localCardRecordPage'));
const BlackCardSecretaryPage = lazy(() => import('../pages/blackCardController/BlackCardSecretaryPage'));
const ApplyEletricBill = lazy(() => import('../pages/eletricBillController/ApplyEletricBill'));
const ApplyEletricBillOTPLoginPage = lazy(() => import('../pages/eletricBillController/ApplyEletricBillOTPLoginPage'));
const PaymentToolPage = lazy(() => import('../pages/paymentToolController/paymentToolPage'));
const PaymentToolCreditResultPage = lazy(() => import('../pages/paymentToolController/paymentToolCreditResultPage'));
const AllotIndex = lazy(() => import('../pages/safeSurfController/Index'));
const AntiVirus = lazy(() => import('../pages/safeSurfController/AntiVirus'));
const Report = lazy(() => import('../pages/safeSurfController/Report'));
const AccountManage = lazy(() => import('../pages/safeSurfController/AccountManage'));
const AllowList = lazy(() => import('../pages/safeSurfController/AllowList'));
const Unsubscribe = lazy(() => import('../pages/safeSurfController/Unsubscribe'));
const KidsSafe = lazy(() => import('../pages/safeSurfController/KidsSafe'));
const TimeManage = lazy(() => import('../pages/safeSurfController/TimeManage'));
const BlockType = lazy(() => import('../pages/safeSurfController/BlockType'));
const RepairArcoaInitPage = lazy(() => import('../pages/repairArcoaController/repairArcoaInitPage'));
const RepairArcoaOnlinePage = lazy(() => import('../pages/repairArcoaController/repairArcoaOnlinePage'));
const ScheduleTrackingPage = lazy(() => import('../pages/repairArcoaController/scheduleTrackingPage'));
const RepairArcoaResultPage = lazy(() => import('../pages/repairArcoaController/repairArcoaResultPage'));
const PrecautionsPage = lazy(() => import('../pages/repairArcoaController/precautionsPage'));
const UnsubscribeSmartplus = lazy(() => import('../pages/smartplus/Unsubscribe'));
const getCallTransferInit = lazy(() => import('../pages/callWaitingController/getCallTransferInit'));
const getLimitCallInit = lazy(() => import('../pages/callWaitingController/getLimitCallInit'));
const spotifyIndex = lazy(() => import('../pages/spotifyController'));
const UnsubscribeSpotify = lazy(() => import('../pages/spotifyController/Unsubscribe'));
const otpForDownload = lazy(() => import('../pages/downloadController/otpForDownload'));

const pagesData = [
  {
    path: '/personalAreaController/personalAreaLayout', // 7.0. 個人專區首頁
    component: PersonalAreaLayout,
    name: 'PersonalAreaLayout',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/personalAreaController/Fcoin',
    component: Fcoin,
    name: 'Fcoin',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/personalAreaController/MemberArea',
    component: MemberArea,
    name: 'MemberArea',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/personalAreaController/personalInfoPage', // 8.1.0_個人專區_帳戶管理_個人資料維護
    component: PersonalInfoPage,
    name: 'PersonalInfoPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/payController/multiplePayDepositPage', // 多門號繳費
    component: MultiplePayDepositPage,
    name: 'MultiplePayDepositPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 7.1.4.1_帳單與用量_補寄帳單_主頁
    // 7.1.4.9_帳單與用量_補寄帳單_補寄繳款條碼_帳單所有門號
    // 7.1.4.15_帳單與用量_補寄帳單_email補寄
    path: '/billResendController/billResend',
    component: BillResend,
    name: 'BillResend',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/orderHistoryController/orderHistoryPage', // 我的訂單
    component: OrderHistoryLayout,
    name: 'OrderHistoryLayout',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billSearchController/getBillSearchInitPage', // 7.1.0 帳單與用量_帳單查詢
    component: GetBillSearchInitPage,
    name: 'GetBillSearchInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billSearchController/getBillSearchOTPLoginPage',
    component: GetBillSearchOTPLoginPage,
    name: 'GetBillSearchOTPLoginPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billSearchController/getPaymentDetailPage', // 7.1.0.4_帳單與用量_帳單查詢_帳單明細
    component: BillPaymentDetails,
    name: 'BillPaymentDetails',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billAndPaymentController/CurrentPaymentPage', // 7.1.1 帳單與用量_本期應繳金額
    component: CurrentPaymentPage,
    name: 'CurrentPaymentPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billSearchController/getSixMonthBillPage', // 7.1.0.7_帳單與用量_六期帳單金額
    component: SixMonthBillPage,
    name: 'SixMonthBillPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billMergeController/billMergePage', // 7.1.12.1_帳單與用量_「合併帳單」申請_step1
    component: MergeBill,
    name: 'MergeBill',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billAndPaymentController/BillNotifySetting', // 7.1.11.1_帳單與用量_帳務及繳款通知設定
    component: BillNotifySettingPage,
    name: 'BillNotifySettingPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/unpaidAmoutAndUsedbytesController/getUnpaidAmoutAndUsedbytesPage', // 7.1.2.1_未出帳金額/上網用量_主頁
    component: GetUnpaidAmoutAndUsedbytesPage,
    name: 'GetUnpaidAmoutAndUsedbytesPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/usedbytesSearchController/usedBytesQueryPage', // 7.1.5 帳單與用量_4G即時用量查詢
    component: UsedBytesSearchPage,
    name: 'UsedBytesSearchPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/four4GExtraBuyController/4GExtraBuyPage', // 7.1.6
    component: FourGExtraBuyPage,
    name: 'FourGExtraBuyPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/four4GExtraBuyController/4GExtraBuyQueryPage', // 7.1.7_帳單與用量_4G加購記錄查詢
    component: FourGPurchaseRecord,
    name: 'FourGPurchaseRecord',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController', // 7.1.8 小額代收
    component: GetPhoneBillPaySettingInitPage,
    name: 'GetPhoneBillPaySettingInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage',
    component: GetPhoneBillPaySettingCheckResetPasswordPage,
    name: 'GetPhoneBillPaySettingCheckResetPasswordPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage',
    component: GetPhoneBillPaySettingSelectAmountPage,
    name: 'GetPhoneBillPaySettingSelectAmountPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage',
    component: GetPhoneBillPaySettingOpenPage,
    name: 'GetPhoneBillPaySettingOpenPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  // {
  //   path: '/phoneBillPaySettingController/getPhoneBillPaySettingLimitsRaised', // 額度調升申請/取消 (合併為額度調整設定)
  //   component: GetPhoneBillPaySettingLimitsRaised,
  //   name: 'GetPhoneBillPaySettingLimitsRaised',
  //   pageType: PageTypes.DynamicWithBannerLayout,
  // },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySmsSetting', // 簡訊通知設定
    component: GetPhoneBillPaySmsSetting,
    name: 'GetPhoneBillPaySmsSetting',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPayFixAmountSetting', // 帳單代收_額度調整需求
    component: GetPhoneBillPayFixAmountSetting,
    name: 'GetPhoneBillPayFixAmountSetting',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billSettingController/addressChangePage', // 7.1.10.1_帳單地址/電話變更
    component: AddressChangePage,
    name: 'AddressChangePage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage',
    component: GetPhoneBillPaySettingSmsValidateErrorPage,
    name: 'GetPhoneBillPaySettingSmsValidateErrorPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/voiceMailController/voiceMailPage', // 7.4.4.1_漫遊與門號服務_語音信箱
    component: VoiceMailPage,
    name: 'VoiceMailPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneRingBellController/phoneRingBell', // 7.4.6_漫遊與門號服務_來電答鈴
    component: PhoneRingBell,
    name: 'PhoneRingBell',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/payExplanationController/getPayExplanationPage', // 7.2.2_繳費項目_繳款方式
    component: PayExplanation,
    name: 'PayExplanation',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/autoPayController/autoPayPage', // 7.2.3_繳費項目_自動扣繳申請/異動/取消首頁
    component: AutoPayPage,
    name: 'AutoPayPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/autoPayController/autoPayApplyPage', // 7.2.3_繳費項目_自動扣繳申請
    component: AutoPayApplyPage,
    name: 'AutoPayApplyPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/autoPayController/autoPayExtendPage', // 7.2.3_繳費項目_自動扣繳異動(信用卡效期展延)
    component: CreditcardDateextension,
    name: 'CreditcardDateextension',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/autoPayController/autoPayCancelPage', // 7.2.3_繳費項目_自動扣繳取消(取消自動轉帳)
    component: CancelAutopayment,
    name: 'CancelAutopayment',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/qRateController/qRateInitPage', // 7.3.0_合約費率_目前費率查詢
    component: QRateInitPage,
    name: 'QRateInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/rateChangeController/rateChangeInitPage', // 7.3.1.1
    component: RateChangeInitPage,
    name: 'RateChangeInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/bestRateController/bestRateQueryPage', // 7.3.2.1_合約費率_推薦費率查詢
    component: BestRateQueryPage,
    name: 'BestRateQueryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/queryContractsInfoController/getQueryContractsInfoPage', // 7.3.3
    component: GetQueryContractsInfoPage,
    name: 'GetQueryContractsInfoPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/freeCourseController/FreeCourseInitPage', // 7.5.5 免費課程報名紀錄
    component: FreeCourseInitPage,
    name: 'FreeCourseInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneFilterController/phoneFilterInitPage', // 7.4.7.1_漫遊與門號服務_來電過濾_主頁
    component: PhoneFilterInitPage,
    name: 'PhoneFilterInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/phoneFilterController/phoneFilterResultPage',
    component: PhoneFilterResultPage,
    name: 'PhoneFilterResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/phoneFilterController/phoneFilterInstruction',
    component: PhoneFilterInstruction,
    name: 'PhoneFilterInstruction',
    pageType: PageTypes.Static,
  },
  {
    path: '/phoneFilterController/phoneFilterBlackListPage', // 7.4.7.7_漫遊與門號服務_來電過濾_過濾名單設定
    component: PhoneFilterBlackListPage,
    name: 'PhoneFilterBlackListPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/volteController/volteSetting', // 7.4.5_漫遊與門號服務_VoLTE
    component: VolteSetting,
    name: 'VolteSetting',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingConfigController/ResumeCall', // 7.4.1  恢復通話
    component: ResumeCall,
    name: 'ResumeCall',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/parkingfeeController/parkingfeeChangeRecordPage', // 7.5.6 停車費代收記錄查詢
    component: ParkingfeeChangeRecordPage,
    name: 'ParkingfeeChangeRecordPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 7.5.1_交易/異動紀錄_門號/服務異動記錄
    // 7.5.1.3_交易/異動紀錄_門號/服務異動記錄_電子帳單申請及變更
    // 7.5.1.7_交易:異動紀錄_門號:服務異動記錄_停車費代收相關
    path: '/queryRecordController/changeRecordPage',
    component: ChangeRecord,
    name: 'ChangeRecord',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/queryRecordController/getQueryRecordPage', // 7.6.0
    component: GetQueryRecordPage,
    name: 'GetQueryRecordPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidRoamingController/prepaidRoamingBuyPage', // 7.6.8_預付卡漫遊上網加購
    component: PrepaidRoamingBuyPage,
    name: 'PrepaidRoamingBuyPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidRoamingController/prepaidRoamingQueryPage',
    component: PrepaidRoamingQueryPage,
    name: 'PrepaidRoamingQueryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidDepositController/prepaidRapidDepositPage',
    component: PrepaidRapidDepositPage,
    name: 'PrepaidRapidDepositPage',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/prepaidExtraBuyController/prepaidExtraBuyPage',
    component: PrepaidExtraBuyPage,
    name: 'PrepaidExtraBuyPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 電子帳單變更
    path: '/eBillSettingController/eBillChangeNoticePage',
    component: EBillChangeNoticePage,
    name: 'EBillChangeNoticePage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/eBillSettingController/eBillChangeHistoryQueryPage',
    component: EBillChangeHistoryQueryPage,
    name: 'EBillChangeHistoryQueryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidDepositController/prepaidDetailQueryPage', // 7.6.2
    component: PrepaidDetailQueryPage,
    name: 'PrepaidDetailQueryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidDepositController/prepaidDepositPage', // 7.6.3
    component: PrepaidDepositPage,
    name: 'PrepaidDepositPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/prepaidDepositController/depositCardPage',
    component: DepositCardPage,
    name: 'DepositCardPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/billBarcodePayController/getBarcodePayInitPage', // 7.2.1
    component: GetBarcodePayInitPage,
    name: 'GetBarcodePayInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/serviceCoverageController/serviceCoveragePage', // 5.9.0
    component: ServiceCoveragePage,
    name: 'ServiceCoveragePage',
    pageType: PageTypes.Dynamic,
  },
  {
    // 取消電子帳單
    path: '/eletricBillController/cancelEletricBillPage',
    component: CancelEletricBill,
    name: 'CancelEletricBill',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/help-center/store', // 5.3
    component: Store,
    name: 'Store',
    pageType: PageTypes.Dynamic,
  },
  {
    // 全虹保固查詢
    path: '/warrantyController/warrantyInitPage',
    component: WarrantyInitPage,
    name: 'WarrantyInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/warrantyController/warrantyInfoPage',
    component: WarrantyInfoPage,
    name: 'WarrantyInfoPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/help-center/numberSearch', // 5.7.0_幫助中心_網內外門號查詢
    component: NumberSearchPage,
    name: 'NumberSearchPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/help-center/reminder', // 5.15.0 幫助中心_服務申請證件提醒
    component: Reminder,
    name: 'Reminder',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/help-center/bookingMsisdn', // 5.16.0 幫助中心_預約門市換號
    component: BookingMsisdn,
    name: 'BookingMsisdn',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/payController/payPage', // 7.2.0
    component: PayPage,
    name: 'PayPage',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/payController/payDepositPage',
    component: PayDepositPage,
    name: 'PayDepositPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanController/roamingPlanHomePage', // 7.4.16 首頁
    component: RoamingPlanHomePage,
    name: 'RoamingPlanHomePage',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/roamingPlanController/applyRoamingOfferPage', // 7.4.16 漫遊申請
    component: ApplyRoamingOfferPage,
    name: 'ApplyRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanController/roamingApplicationRecordPage', // 7.4.16 紀錄查詢
    component: RoamingApplicationRecordPage,
    name: 'RoamingApplicationRecordPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanController/attachRoamingOfferPage', // 7.4.16 加購
    component: AttachRoamingOfferPage,
    name: 'AttachRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanController/cancelRoamingOfferPage', // 7.4.16 取消
    component: CancelRoamingOfferPage,
    name: 'CancelRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanController/modifyRoamingOfferPage', // 7.4.16 修改結束時間
    component: ModifyRoamingOfferPage,
    name: 'ModifyRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 外部系統介接專用 start
    path: '/roamingPlanInternalAgentController/internalAgentApplyRoamingOfferPage', // 7.4.16 漫遊申請
    component: InternalAgentApplyRoamingOfferPage,
    name: 'InternalAgentApplyRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/roamingPlanInternalAgentController/internalAgentAttachRoamingOfferPage', // 7.4.16 加購
    component: InternalAgentAttachRoamingOfferPage,
    name: 'InternalAgentAttachRoamingOfferPage',
    pageType: PageTypes.DynamicWithBannerLayout,
    // 外部系統介接專用 end
  },
  {
    path: '/parkingfeeController/parkingfeeInfoPage', // 7.4.22
    component: ParkingfeeInfoPage,
    name: 'ParkingfeeInfoPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/parkingfeeController/parkingfeeInitPage', // 7.4.22
    component: ParkingfeeInitPage,
    name: 'ParkingfeeInitPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/parkingfeeController/parkingfeeReceiveMoneyRecordPage',
    component: ParkingfeeReceiveMoneyRecordPage,
    name: 'ParkingfeeReceiveMoneyRecordPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/resultController/ResultPage',
    component: ResultPage,
    name: 'ResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/resultController/ResultWithCampaignPage',
    component: ResultWithCampaignPage,
    name: 'ResultWithCampaignPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/resultController/ProcedurePage',
    component: ProcedurePage,
    name: 'ProcedurePage',
    pageType: PageTypes.Static,
  },
  {
    path: '/internationalController/International', // 7.4.12.1_漫遊與門號服務_撥打國際電話_主頁
    component: International,
    name: 'International',
    pageType: PageTypes.Static,
  },
  {
    // 信用卡繳款結果頁，由外部導入
    path: '/prepaidRoamingController/prepaidRoamingCreditCardPage',
    component: PrepaidRoamingCreditCardPage,
    name: 'PrepaidRoamingCreditCardPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/phoneBillPaySettingController/getPhoneBillPaySettingResultPage',
    component: GetPhoneBillPaySettingResultPage,
    name: 'GetPhoneBillPaySettingResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/prepaidExtraBuyController/prepaidExtrsBuyCreditCardPage',
    component: PrepaidExtrsBuyCreditCardPage,
    name: 'PrepaidExtrsBuyCreditCardPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/prepaidDepositController/prepaidDepositCreditCardSuccess',
    component: PrepaidDepositCreditCardSuccess,
    name: 'PrepaidDepositCreditCardSuccess',
    pageType: PageTypes.Static,
  },
  {
    path: '/payController/creditCardPayResultPage',
    component: CreditcardPayResultPage,
    name: 'CreditcardPayResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/payController/MultipleCreditCardPayResultPage',
    component: MultipleCreditCardPayResultPage,
    name: 'MultipleCreditCardPayResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/roamingConfigController/PUKSearch', // 7.4.3.1_漫遊與門號服務_PUK解卡密碼查詢
    component: PUKSearch,
    name: 'PUKSearch',
    pageType: PageTypes.Static,
  },
  {
    path: '/happyGoController/happyGoLayout', // 8.2 Happy Go
    component: HappyGoLayout,
    name: 'HappyGoLayout',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 維修進度查詢
    path: '/warrantyController/repairProgressInitPage',
    component: RepairProgressInit,
    name: 'RepairProgressInit',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/warrantyController/repairProgressInfoPage',
    component: RepairProgressInfo,
    name: 'RepairProgressInfo',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/electronicInvoiceController/ElectronicInvoiceSetting', // 8.1.5.3 電信電子發票設定 & 8.1.5.1 加值服務電子發票設定
    component: ElectronicInvoceSetting,
    name: 'ElectronicInvoceSetting',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/memberDataController/epaperSubscribePage', // 8.1.1_個人專區_帳戶管理_訂閱電子報
    component: EpaperSubscribePage,
    name: 'EpaperSubscribePage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/lossReportController/lossReportPage', // 7.4.0_漫遊與門號服務_掛失服務
    component: LossReport,
    name: 'LossReport',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/storeReserveController/storeReservePage', // 7.5.4_交易異動紀錄_預約門市服務
    component: StoreReserve,
    name: 'StoreReserve',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/localCardEsimController/applyHistoryPage', // 遠遊卡eSIM
    component: LocalCardEsimHistoryPage,
    name: 'LocalCardEsimHistoryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/blackCardController/secretaryPage', // 黑卡秘書
    component: BlackCardSecretaryPage,
    name: 'BlackCardSecretaryPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 電子帳單申請、OTP 登入頁
    path: '/eletricBillController/applyEletricBillPage',
    component: ApplyEletricBill,
    name: 'ApplyEletricBill',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/eletricBillController/applyEletricBillOTPLoginPage',
    component: ApplyEletricBillOTPLoginPage,
    name: 'ApplyEletricBillOTPLoginPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    // 付款工具維護
    path: '/paymentToolController/paymentToolSettingPage',
    component: PaymentToolPage,
    name: 'PaymentToolPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/paymentToolController/paymentToolCreditResultPage',
    component: PaymentToolCreditResultPage,
    name: 'PaymentToolCreditResultPage',
    pageType: PageTypes.Static,
  },
  {
    // 遠傳守護網
    path: '/safeSurfController/index',
    component: AllotIndex,
    name: 'AllotIndex',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/safeSurfController/antivirus/config',
    component: AntiVirus,
    name: 'AntiVirus',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/antivirus/report',
    component: Report,
    name: 'Report',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/safeSurfController/parentalControls/report',
    component: Report,
    name: 'Report',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/safeSurfController/accountManage',
    component: AccountManage,
    name: 'AccountManage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/antivirus/config/allowlist',
    component: AllowList,
    name: 'AllowList',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/unsubscribe',
    component: Unsubscribe,
    name: 'Unsubscribe',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/parentalControls/config',
    component: KidsSafe,
    name: 'KidsSafe',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/parentalControls/config/timeManage',
    component: TimeManage,
    name: 'TimeManage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/safeSurfController/parentalControls/config/blockType',
    component: BlockType,
    name: 'BlockType',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/repairArcoaController/repairArcoaInitPage', // 手機維修 主頁
    component: RepairArcoaInitPage,
    name: 'RepairArcoaInitPage',
    pageType: PageTypes.Dynamic,
  },
  {
    path: '/repairArcoaController/repairArcoaOnlinePage', // 手機維修 線上維修
    component: RepairArcoaOnlinePage,
    name: 'RepairArcoaOnlinePage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/repairArcoaController/scheduleTrackingPage', // 手機維修 訂單進度查詢
    component: ScheduleTrackingPage,
    name: 'ScheduleTrackingPage',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/repairArcoaController/repairArcoaResultPage', // 手機維修 共用結果頁
    component: RepairArcoaResultPage,
    name: 'RepairArcoaResultPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/repairArcoaController/precautionsPage', // 手機維修 注意事項頁
    component: PrecautionsPage,
    name: 'PrecautionsPage',
    pageType: PageTypes.Static,
  },
  {
    path: '/smartPlusController/unsubscribe', // 取消數位萬事通服務
    component: UnsubscribeSmartplus,
    name: 'UnsubscribeSmartplus',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/callWaitingController/getCallTransferInit', // 指定轉接
    component: getCallTransferInit,
    name: 'getCallTransferInit',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/callWaitingController/getLimitCallInit', // 通話限制
    component: getLimitCallInit,
    name: 'getLimitCallInit',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/spotifyController/index', // Spotify註冊頁
    component: spotifyIndex,
    name: 'spotifyIndex',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/spotifyController/unsubscribe', // Spotify取消
    component: UnsubscribeSpotify,
    name: 'unsubscribeSpotify',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
  {
    path: '/downloadController/otpForDownload', // 通話限制
    component: otpForDownload,
    name: 'otpForDownload',
    pageType: PageTypes.DynamicWithBannerLayout,
  },
];

export default pagesData;
